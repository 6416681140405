import {
  CardView,
  OrderRewardResponse,
  V1CardJamWorkspaceCardSetListResponse,
  WorkspaceCardSetResponse
} from "common/api/requests";
import { capitalize } from "common/utils";
import { AttributeReward, DesignReward, RewardItem } from "store/reward/reward";
import { v4 } from "uuid";

export type RewardView = {
  key: string;
  name: string;
  quantity: number;
  previewUrl: string;
};

export const getVariantDisplayName = (
  sets: WorkspaceCardSetResponse[],
  setId: string,
  template: string,
  variantBase: string
): string => {
  const set = sets.find((s) => s.id === setId);
  const setName = set?.name;
  const variantBases = set?.card_set_template_variant_bases;
  const baseName =
    variantBases?.find(
      (base) => base.card_set_template === template && base.card_variant_base === variantBase
    )?.name ?? variantBase;
  const normalizedBaseName = baseName.replace("-", "_").split("_").map(capitalize).join(" ");
  return `${setName} ${normalizedBaseName}`;
};

export const getAttributeDisplayName = (attribute: string) => {
  return attribute.replace("-", "_").split("_").map(capitalize).join(" ");
};

export const getAttributeUrl = (
  sets: V1CardJamWorkspaceCardSetListResponse,
  attribute: string
): string => {
  const attributes = sets?.results.flatMap((set) => set.card_variant_attributes);
  return (
    attributes?.find((a) => a.card_variant_attribute === attribute)?.card_sample_front_url ?? ""
  );
};

export const rewardOnlyHasDefaultDesigns = (
  reward: OrderRewardResponse,
  sets: V1CardJamWorkspaceCardSetListResponse
) => {
  return (
    reward.attributes_won_list.length === 0 &&
    reward.designs_won_list.every((d) =>
      isDefaultDesignForSet(
        sets.results ?? [],
        d.card_set_id,
        d.card_set_template,
        d.card_variant_base
      )
    )
  );
};

export const getRewardViews = (
  reward: OrderRewardResponse,
  sets: V1CardJamWorkspaceCardSetListResponse
): RewardView[] => {
  const designs = reward.designs_won_list.map<RewardView>((design) => ({
    key: `${design.card_set_id}${design.card_variant_base}`,
    name: getVariantDisplayName(
      sets.results,
      design.card_set_id,
      design.card_set_template,
      design.card_variant_base
    ),
    quantity: design.count,
    previewUrl: design.card_sample_front_url
  }));
  const attributes = reward.attributes_won_list.map<RewardView>((attribute) => ({
    key: v4(),
    name: getAttributeDisplayName(attribute.card_variant_attribute),
    quantity: attribute.count,
    previewUrl: getAttributeUrl(sets, attribute.card_variant_attribute)
  }));
  return [...designs, ...attributes];
};

export const isDefaultDesignForSet = (
  sets: WorkspaceCardSetResponse[],
  setId: string,
  template: string,
  variantBase: string
): boolean => {
  return (
    sets
      ?.find((s) => s.id === setId)
      ?.card_set_template_variant_bases?.find(
        (base) => base.card_set_template === template && base.card_variant_base === variantBase
      )?.default ?? false
  );
};

export const isCardLocked = (
  card: CardView,
  sets: V1CardJamWorkspaceCardSetListResponse
): boolean => {
  return (
    card.card_variant_attributes.length > 0 ||
    !isDefaultDesignForSet(
      sets.results ?? [],
      card.card_set_id,
      card.card_set_template,
      card.card_variant_base
    )
  );
};

export const getRewardItems = (
  reward: OrderRewardResponse,
  sets: V1CardJamWorkspaceCardSetListResponse
): RewardItem[] => {
  const designs = reward.designs_won_list
    .filter(
      (design) =>
        !isDefaultDesignForSet(
          sets.results ?? [],
          design.card_set_id,
          design.card_set_template,
          design.card_variant_base
        )
    )
    .map<DesignReward>((design) => ({
      type: "design",
      id: v4(),
      name: getVariantDisplayName(
        sets.results,
        design.card_set_id,
        design.card_set_template,
        design.card_variant_base
      ),
      quantity: design.count,
      remaining: design.count,
      value: {
        cardSetId: design.card_set_id,
        cardSetTemplate: design.card_set_template,
        cardVariantBase: design.card_variant_base
      }
    }));
  const attributes = reward.attributes_won_list.map<AttributeReward>((attribute) => ({
    type: "attribute",
    id: v4(),
    name: attribute.card_variant_attribute.replace("-", "_").split("_").map(capitalize).join(" "),
    quantity: attribute.count,
    remaining: attribute.count,
    value: {
      name: attribute.card_variant_attribute
    }
  }));
  return [...designs, ...attributes];
};
