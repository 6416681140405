import { AboutAthleteBackStep } from "store/order-form/card";
import * as yup from "yup";
import { State, stateLabels } from "./state-labels";
import { useOrderFormStore } from "store/order-form/order-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "@/components/buttons/button";
import { ErrorMessage } from "../error-message";
import FormInput from "../form-input";
import { useHookFormMask } from "use-mask-input";
import { isEqual } from "lodash";
import { cn } from "@/utils/index";
import { format } from "date-fns";
import ButtonFooter from "../button-footer";
import { useGetPlayerCard } from "hooks/card/useGetPlayerCard";
import isApiError from "@/utils/api/is-api-error";
import { showToast } from "legend-ui";
import { useUpdateCard } from "./use-update-card";

export type AboutAthleteBackStepFormValues = Omit<AboutAthleteBackStep, "athleteDob"> & {
  athleteDob: Date;
};

type Props = {
  onBack: () => void;
  onSubmit: (values: AboutAthleteBackStep, backInfoChanged: boolean) => void;
};

const schema = yup.object({
  athleteCity: yup.string().trim().required("City is required"),
  athleteState: yup
    .string()
    .oneOf([...Object.values(State)], "State is required")
    .required("State is required"),
  athleteDob: yup
    .date()
    .typeError("Date of birth is required")
    .required("Date of birth is required")
    .min(new Date(new Date().getFullYear() - 150, 0, 1), "Date is out of range")
    .max(new Date(), "Date is out of range"),
  nickname: yup.string().trim().optional(),
  lastSeasonStatus: yup.string().trim().optional(),
  otherSports: yup.string().trim().optional(),
  favoriteActivities: yup.string().trim().optional()
});

const fieldMappings = {
  city: "city",
  state: "state",
  age: "age",
  nickname: "nickname",
  season_description: "season description",
  other_sports: "other sports",
  hobby: "hobby"
};

const apiFieldFormKeyMapping: Record<string, keyof AboutAthleteBackStepFormValues> = {
  city: "athleteCity",
  state: "athleteState",
  age: "athleteDob",
  nickname: "nickname",
  season_description: "lastSeasonStatus",
  other_sports: "otherSports",
  hobby: "favoriteActivities"
};

export default function AboutAthleteBackForm({ onSubmit, onBack }: Props) {
  const { cardStepData } = useOrderFormStore();
  const defaultValues = cardStepData?.aboutAthleteBackStep;
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors }
  } = useForm<AboutAthleteBackStepFormValues>({ resolver: yupResolver(schema) });
  const state = watch("athleteState");
  const registerWithMask = useHookFormMask(register);
  const { isGeneratingCard, isLoadingCard } = useGetPlayerCard();

  const onUpdateError = (error: unknown) => {
    if (isApiError(error)) {
      const athleteInfoMessages = error.body?.["athlete_info"];
      const fields = [
        "city",
        "state",
        "age",
        "nickname",
        "season_description",
        "other_sports",
        "hobby"
      ];
      for (const field of fields) {
        const message = athleteInfoMessages?.[field];
        const fieldName = fieldMappings[field];
        const formKey = apiFieldFormKeyMapping[field];
        if (message && fieldName && formKey) {
          showToast(`We ran into an issue with your ${fieldName}: ${message}`, { duration: 8000 });
          setError(formKey, { message });
          return;
        }
      }
    }
    showToast("We ran into an unexpected issue updating your card. Please try again.");
  };

  const { updateCard } = useUpdateCard({
    onUpdateError
  });

  const handleApiSubmit = async (values: AboutAthleteBackStepFormValues) => {
    const stepValue: AboutAthleteBackStep = {
      ...values,
      athleteDob: values.athleteDob.toISOString()
    };
    const backInfoChanged = !isEqual(cardStepData.aboutAthleteBackStep, stepValue);
    const success = await updateCard(cardStepData, stepValue, backInfoChanged);
    if (success) {
      onSubmit(stepValue, backInfoChanged);
    }
  };

  return (
    <form className="px-4 pt-2 md:px-0 md:pt-6" onSubmit={handleSubmit(handleApiSubmit)}>
      <div className="grid gap-4">
        <div>
          <div className="flex flex-col">
            <label className="inline-block pb-2">What city and state is the athlete from?</label>
          </div>
          <div className="grid grid-cols-2 items-start gap-x-3 md:gap-x-4">
            <div className="flex flex-col">
              <FormInput
                placeholder="City"
                defaultValue={defaultValues?.athleteCity}
                {...register("athleteCity")}
              />
              {errors.athleteCity && <ErrorMessage>{errors.athleteCity.message}</ErrorMessage>}
            </div>
            <div className="flex flex-col">
              <div className="relative">
                <select
                  autoComplete="address-level1"
                  className={cn(
                    "w-full cursor-pointer appearance-none rounded-full border border-gray-300 bg-white px-4 py-2 focus:bg-white",
                    {
                      "text-gray-400": !state
                    }
                  )}
                  defaultValue={defaultValues?.athleteState ?? ""}
                  {...register("athleteState")}
                >
                  <option value="" disabled hidden>
                    State
                  </option>
                  {...Object.entries(stateLabels).map(([state, label]) => (
                    <option key={state} value={state}>
                      {label}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                  <svg
                    className="h-4 w-4 text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              {errors.athleteState && <ErrorMessage>{errors.athleteState.message}</ErrorMessage>}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <FormInput
            label="Athlete's date of birth"
            placeholder="mm/dd/yyyy"
            defaultValue={
              defaultValues?.athleteDob
                ? format(new Date(defaultValues.athleteDob), "MM/dd/yyyy")
                : undefined
            }
            {...registerWithMask("athleteDob", "datetime", {
              inputFormat: "mm/dd/yyyy"
            })}
          />
          {errors.athleteDob && <ErrorMessage>{errors.athleteDob.message}</ErrorMessage>}
        </div>
        <div className="flex flex-grow flex-col">
          <FormInput
            label="Do they have a nickname? If so what is it?"
            placeholder="Nickname (optional)"
            defaultValue={defaultValues?.nickname}
            {...register("nickname")}
          />
          {errors.nickname && <ErrorMessage>{errors.nickname.message}</ErrorMessage>}
        </div>
        <div className="flex flex-col">
          <FormInput
            label="What was their favorite part about last season or their last game?"
            placeholder="E.g. The previous game went great!"
            defaultValue={defaultValues?.lastSeasonStatus}
            {...register("lastSeasonStatus")}
          />
          {errors.lastSeasonStatus && (
            <ErrorMessage>{errors.lastSeasonStatus.message}</ErrorMessage>
          )}
        </div>
        <div className="flex flex-col">
          <FormInput
            label="What other sports do they play?"
            placeholder="E.g. Soccer, volleyball"
            defaultValue={defaultValues?.otherSports}
            {...register("otherSports")}
          />
          {errors.otherSports && <ErrorMessage>{errors.otherSports.message}</ErrorMessage>}
        </div>
        <div className="flex flex-col">
          <FormInput
            label="What other non-sports hobbies do they enjoy?"
            placeholder="E.g. Kayaking, hiking"
            defaultValue={defaultValues?.favoriteActivities}
            {...register("favoriteActivities")}
          />
          {errors.favoriteActivities && (
            <ErrorMessage>{errors.favoriteActivities.message}</ErrorMessage>
          )}
        </div>
      </div>
      <ButtonFooter className="md:pt-8">
        <Button type="button" className="flex-grow md:grow-0" variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          className="flex-grow md:grow-0"
          loading={isGeneratingCard || isLoadingCard}
          disabled={isGeneratingCard || isLoadingCard}
        >
          Next
        </Button>
      </ButtonFooter>
    </form>
  );
}
