import { SwaggerCardView } from "common/api/requests";
import { useCallback, useEffect, useRef, useState } from "react";

export type ProgressMetadata = {
  value: number;
  expectedSteps?: number;
  currentStep: number;
  currentStepName?: string;
};

type ProgressParams = {
  cardResult?: SwaggerCardView;
};

const slowStepNames = ["blurb_generation"];

export default function useProgress({ cardResult }: ProgressParams) {
  const intervalRef = useRef<number>();
  const progressRef = useRef<ProgressMetadata>();
  const pendingJobStartRef = useRef<boolean>(false);
  const [progress, setProgress] = useState<ProgressMetadata>();
  const [pollingForProgress, setPollingForProgress] = useState(false);

  const getRate = (value: number, stepName: string) => {
    if (value < 15) return 4;
    if (value < 30) return 1.5;
    if (value < 45 || value > 80) return 1;
    if (slowStepNames.includes(stepName)) return 3;
    return 1.75;
  };

  const updateProgress = useCallback(() => {
    const currentProgress = progressRef.current;
    const { currentStep, expectedSteps, currentStepName, value } = currentProgress;
    const minProgress = expectedSteps ? ((currentStep - 1) / expectedSteps) * 100 : 0;
    const maxProgress = expectedSteps ? (currentStep / expectedSteps) * 100 : 20;
    const rate = getRate(value, currentStepName);
    const canGoPastMax = slowStepNames.includes(currentStepName) || value < 50;
    const shouldIncrement = canGoPastMax || value < maxProgress;

    if (value < minProgress) {
      setProgress((prev) => ({ ...prev, value: minProgress }));
    } else if (shouldIncrement) {
      setProgress((prev) => ({ ...prev, value: prev.value + rate }));
    }
  }, []);

  const isCardResultCompleted = (cardResult?: SwaggerCardView) => {
    return cardResult?.card_processing_status === "completed";
  };

  useEffect(() => {
    if (pollingForProgress) {
      intervalRef.current = setInterval(updateProgress, 750) as unknown as number;
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [pollingForProgress, updateProgress]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    if (!cardResult) return;
    if (pendingJobStartRef.current) {
      if (isCardResultCompleted(cardResult)) {
        return;
      }
      pendingJobStartRef.current = false;
    }
    if (cardResult.card_processing_status === "failed" && pollingForProgress) {
      setPollingForProgress(false);
      return;
    }
    if (cardResult.card_processing_status === "completed" && pollingForProgress) {
      const completeProgress = {
        value: 100,
        expectedSteps: 1,
        currentStep: 1
      };
      progressRef.current = completeProgress;
      setProgress(completeProgress);
      const pollingTimeout = setTimeout(() => setPollingForProgress(false), 750);
      return () => {
        clearTimeout(pollingTimeout);
      };
    }
    if (["submitted", "variant_submitted"].includes(cardResult.card_processing_status)) {
      return;
    }

    const stepCount = cardResult.card_creation_step_status?.card_create_step_count;
    const currentStep = cardResult.card_creation_step_status?.card_create_step_current;
    if (stepCount >= 0 && currentStep >= 0) {
      setProgress((prev) => ({
        ...prev,
        expectedSteps: stepCount + 1,
        currentStep: currentStep + 1
      }));
    }
  }, [cardResult]);

  const resetProgress = () => {
    setProgress({
      value: 0,
      currentStep: 0
    });
  };

  const startPolling = () => {
    if (isCardResultCompleted(cardResult) || !cardResult) {
      pendingJobStartRef.current = true;
    }
    resetProgress();
    setPollingForProgress(true);
  };

  const stopPolling = () => {
    pendingJobStartRef.current = false;
    setPollingForProgress(false);
  };

  return {
    progress,
    resetProgress,
    pollingForProgress,
    startPolling,
    stopPolling
  };
}
