import Button from "../buttons/button";
import * as Progress from "@radix-ui/react-progress";
import { CardLayout } from "./CardLayout";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "legend-ui";

type Props = {
  progress?: number;
  text?: string;
  showText?: boolean;
  onCancel?: () => void;
};

const quotes = [
  "A champion is someone who keeps making Stat Legend cards even when they can't.",
  "A Stat Legend card needs a motivation above and beyond the norm.",
  "A trophy carries dust. Stat Legend cards create memories that last forever.",
  "Adversity causes some cards to be discarded; others to become Stat Legends.",
  "Age is no barrier for creating Stat Legend cards. It's a limitation you put on your mind.",
  "Champions aren't made in the gyms. Champions are made from the creativity and passion that go into every Stat Legend card.",
  "Champions keep playing until they create the perfect Stat Legend card.",
  "Champions keep playing until they get it right; Stat Legend cards keep inspiring until they get it perfect.",
  "Creating a Stat Legend card isn't everything, it's the only thing.",
  "Do not let what you cannot do interfere with making Stat Legend cards.",
  "Do you know what my favorite part of making Stat Legend cards is? The opportunity to create.",
  "Excellence in Stat Legend cards is the gradual result of always striving to do better.",
  "Hard work beats talent when talent doesn't work hard on Stat Legend cards.",
  "I always felt that my greatest asset was not my physical ability, but my mental ability to create Stat Legend cards.",
  "I've failed over and over and over again in my life, and that is why I create Stat Legend cards.",
  "If you fail to prepare, you're prepared to fail in making a Stat Legend card.",
  "It ain't over till you finish your Stat Legend card.",
  "It is not the size of the Stat Legend card but the size of the heart in its creation that matters.",
  "It's hard to beat a Stat Legend card that never gives up.",
  "It's not the size of the card in the fight, but the size of the fight in the card that makes a Stat Legend.",
  "It's not the will to win that matters - everyone has that. It's the will to prepare a Stat Legend card that matters.",
  "It's not whether you get knocked down; it's whether you keep making Stat Legend cards.",
  "It's not whether your Stat Legend card gets knocked down; it's whether it gets up.",
  "Make sure your worst enemy doesn't live between your own two ears when designing Stat Legend cards.",
  "Never give up! Failure and rejection are only the first step to succeeding with Stat Legend cards.",
  "Never let your head hang down. Never give up on creating the perfect Stat Legend card. Find another way.",
  "Never say never because limits, like fears, are often just an illusion when creating Stat Legend cards.",
  "Pain is temporary. Stat Legend cards last forever.",
  "Stat Legend cards aren't really made of gold. They're made of sweat, determination, and a hard-to-find alloy called guts.",
  "Stat Legend cards serve the community by providing vivid examples of excellence.",
  "Success is no accident. It is hard work, perseverance, learning, studying, sacrifice, and most of all, love of making Stat Legend cards.",
  "The difference between the impossible and the possible lies in a Stat Legend card's determination.",
  "The harder the battle to make a Stat Legend card, the sweeter the victory.",
  "The harder the conflict in making a Stat Legend card, the more glorious the triumph.",
  "The highest compliment that you can pay me is to say that I work hard every day on Stat Legend cards, that I never dog it.",
  "The more difficult the Stat Legend card, the greater the happiness in making it.",
  "The only way to prove that you're a good sport is to make a Stat Legend card.",
  "The principle is competing against yourself. It's about self-improvement, about making each Stat Legend card better than the last.",
  "The strength of the team is each Stat Legend card. The strength of each card is the team.",
  "The will to win is important, but the will to prepare the best Stat Legend card is vital.",
  "What you lack in talent can be made up with desire, hustle, and giving 110% all the time when creating Stat Legend cards.",
  "Winning is not everything, but wanting to create the best Stat Legend card is.",
  "You can't put a limit on anything. The more you dream, the farther your Stat Legend cards get.",
  "You have to design something in your life that is honorable and not ordinary if you are to create a true Stat Legend card.",
  "You have to expect great things of your Stat Legend cards before you can create them.",
  "You miss 100% of the Stat Legend cards you don't create.",
  "You can do it, put your Stat Legend card into it. "
];

const getRandomQuote = () => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
};

export const LoadingCard = ({ progress, text, onCancel, showText = true }: Props = {}) => {
  const [currentText, setCurrentText] = useState(text ?? getRandomQuote());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(getRandomQuote());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <CardLayout>
      <div className="flex h-full flex-col items-center space-y-4 px-4 text-center text-white">
        <div className="flex w-full justify-center pt-[130px] md:pt-[160px] xl:pt-[180px]">
          {progress === undefined ? (
            <LoadingIndicator variant="white" />
          ) : (
            <Progress.Root className="translate-z-0 relative z-0 mb-4 h-2 w-10/12 overflow-hidden rounded-full bg-black/60">
              <Progress.Indicator
                className="h-full w-full bg-white [transition:transform_660ms_cubic-bezier(0.65,0,0.35,1)]"
                style={{ transform: `translateX(-${100 - progress}%)` }}
              />
            </Progress.Root>
          )}
        </div>
        {showText && (
          <div>
            <p className="text-sm">{currentText}</p>
          </div>
        )}
        {onCancel && (
          <Button variant="transparent" className="underline" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </CardLayout>
  );
};
