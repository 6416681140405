import { AthleteInfoView, CardUpdateView } from "common/api/requests";
import { AboutAthleteBackStep, AboutAthleteFrontStep } from "store/order-form/card";
import { State } from "./state-labels";

const calculateAge = (dateOfBirth: string) => {
  const dob = new Date(dateOfBirth);
  const today = new Date();

  let age = today.getFullYear() - dob.getFullYear();
  const m = today.getMonth() - dob.getMonth();

  // Check if the current month is before the birth month
  // or if it's the birth month but the current day is before the birth day
  if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  return age;
};

export type AboutAthleteValues = AboutAthleteFrontStep &
  AboutAthleteBackStep & {
    blurb?: string;
    mediumBlurb?: string;
    shortBlurb?: string;
    gender?: string;
    skinTone?: AthleteInfoView["skin_tone_selected"];
  };

export const getCardUpdateView = (values: Partial<AboutAthleteValues>): CardUpdateView => {
  const age = values.athleteDob ? calculateAge(values.athleteDob) : 0;

  const cardValues: CardUpdateView = {
    athlete_info: {
      position: values.position || "Your Position",
      team_name: values.team || "Your Team Name",
      full_name:
        [values.firstName, values.lastName]
          .filter((n) => !!n)
          .join(" ")
          .trim() || "Your Name",
      first_name: values.firstName || "Your",
      last_name: values.lastName || "Name",
      player_number: values.number || 1,
      age: values.athleteDob ? age : 0,
      city: values.athleteCity || "City",
      state: values.athleteState || State.KS,
      blurb: values.blurb ?? undefined,
      short_blurb: values.shortBlurb ?? undefined,
      medium_blurb: values.mediumBlurb ?? undefined,
      primary_sport: values.sport || "Sport",
      season_description: values.lastSeasonStatus || "Season Description",
      other_sports: values.otherSports || "Other Sports",
      hobby: values.favoriteActivities || "Hobby",
      nickname: values.nickname || undefined,
      gender: values.gender,
      skin_tone_selected: values.skinTone
    }
  };

  return cardValues;
};
