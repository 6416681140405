declare global {
  interface Window {
    fbq?: any;
  }
}

let eventQueueProcessingScheduled = false;
let eventQueue: any[] = [];
const retries = 5;
let currentTry = 1;

const isFbqLoaded = () => typeof window !== "undefined" && typeof window.fbq !== "undefined";

const processEventQueue = () => {
  eventQueueProcessingScheduled = false;
  if (currentTry > retries) {
    return;
  }
  if (!isFbqLoaded()) {
    eventQueueProcessingScheduled = true;
    currentTry++;
    setTimeout(() => {
      processEventQueue();
    }, 2000);
    return;
  }
  currentTry = 1;
  for (const args of eventQueue) {
    window.fbq?.(...args);
  }
  eventQueue = [];
};

export const sendMetaEvent = (...args: any[]) => {
  eventQueue.push(args);
  if (!eventQueueProcessingScheduled) {
    processEventQueue();
  }
};
