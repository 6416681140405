import { cn } from "@/utils/index";
import React from "react";

const Stepper = ({ children }) => {
  return <div className="flex items-center justify-between">{children}</div>;
};

const Step = ({ onClick, children }) => {
  return (
    <div
      className="relative flex h-7 w-7 select-none items-center justify-center"
      onClick={() => onClick()}
    >
      {children}
    </div>
  );
};

const Typography = ({ variant, children, ...props }) => {
  return <div {...props}>{children}</div>;
};

type StepBarProps = {
  stepIndex: number;
  flow?: string;
};

const StepBar = ({ stepIndex, flow }: StepBarProps) => {
  const steps =
    flow === "team"
      ? ["Photo", "About athlete", "Back design", "Add to team"]
      : ["Photo", "About athlete", "Back design", "Quantity", "Review"];
  const stepText = (text: string) => {
    return <div className="text-sm leading-snug">{text}</div>;
  };

  return (
    <div className="night-mode-hidden bg-[#914dec] md:mx-4 md:mb-[1.9rem] md:mt-4 md:rounded-full">
      <div className="w-full px-4 py-2 md:px-16">
        <Stepper>
          {steps.map((step, idx) => (
            <CreationStep
              key={idx}
              isActive={stepIndex == idx}
              markerText={idx + 1}
              descriptionText={stepText(step)}
              onClick={() => null}
            />
          ))}
        </Stepper>
      </div>
    </div>
  );
};

const CreationStep = ({ isActive, markerText, descriptionText, onClick }) => {
  return (
    <Step onClick={() => onClick()}>
      <StepMarker isSelected={isActive} text={markerText} />
      <div className="absolute -bottom-[2.5rem] hidden w-max text-center md:block">
        <Typography
          variant="h6"
          className={cn({
            "text-black": isActive,
            "text-zinc-300": !isActive,
            "font-semibold": isActive
          })}
        >
          {descriptionText}
        </Typography>
      </div>
    </Step>
  );
};

const StepMarker = ({ text, isSelected }) => {
  return (
    <div
      style={{ backgroundColor: isSelected ? "white" : "#a771f0" }}
      className="flex h-7 w-7 items-center justify-center rounded-full font-bold"
    >
      <div className="text-xs text-[#914dec]">{text}</div>
    </div>
  );
};

export default StepBar;
