import { useV1ServiceV1OrderManagementGet } from "common/api/queries";

export const useFetchCardProductPricing = () => {
  // TODO: Fix codegen here for this query. Naming is off
  const { data: productPricingData, ...options } = useV1ServiceV1OrderManagementGet({
    productType: "card"
  });

  return { ...options, productPricingData };
};
