/* eslint-disable @next/next/no-img-element */
import { sendMetaEvent } from "@/utils/send-meta-event";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendGAEvent } from "@next/third-parties/google";
import { useV1ServiceUserManagementGuestUsersChangeEmailPost } from "common/api/queries";
import useAuth from "hooks/useAuth";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Button,
  LoadingIndicator,
  showToast,
  Input,
  ErrorMessage
} from "legend-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
  open: boolean;
  onClose: () => void;
  referredEmail?: string;
};

const schema = yup.object({
  email: yup.string().email("A valid email is required").required("A valid email is required")
});

type EmailFormValues = {
  email: string;
};

export const StartGuestSessionDialog = (props: Props) => {
  const { mutateAsync: updateEmail, isPending: updatingEmail } =
    useV1ServiceUserManagementGuestUsersChangeEmailPost();
  const { startGuestSession, isLoadingSession, session } = useAuth();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EmailFormValues>({ resolver: yupResolver(schema) });

  const startSession = async (adultUser: boolean) => {
    const newSession = await startGuestSession({ adult_user: adultUser });
    if (!newSession) {
      showToast("We ran into an issue starting your session. Please try again.");
      return;
    }
    if (props.referredEmail) {
      try {
        await updateEmail({
          userId: newSession.workspaceId,
          data: {
            email: props.referredEmail
          }
        });
        sendGAEvent("event", "email_prompt_used_referred_email");
      } catch (e) {
        //
      } finally {
        props.onClose();
      }
    } else {
      sendGAEvent("event", "email_prompt_shown");
      setShowEmailForm(true);
    }
  };

  const saveEmail = async (values: EmailFormValues) => {
    try {
      await updateEmail({
        userId: session?.workspaceId ?? "",
        data: {
          email: values.email
        }
      });
      sendGAEvent("event", "email_prompt_submitted");
      sendMetaEvent("track", "Lead");
      props.onClose();
    } catch (e) {
      showToast("We ran into an unexpected issue saving your email. Please try again.");
    }
  };

  const skipEmailPrompt = () => {
    sendGAEvent("event", "email_prompt_skipped");
    props.onClose();
  };

  const renderGuestSessionPrompt = () => {
    return (
      <>
        <DialogTitle className="text-center leading-6">Confirm your age</DialogTitle>
        <DialogDescription className="py-2 text-center">
          If you are 18 or older you can create an account with StatLegend on your own. If you are
          17 or younger, we&apos;ll ask to provide your parents&apos; email to finish the
          registration.
        </DialogDescription>

        <div className="flex flex-col items-center justify-center gap-y-2 pt-3">
          {isLoadingSession || updatingEmail ? (
            <LoadingIndicator />
          ) : (
            <>
              <Button onClick={() => startSession(true)} variant="white" className="w-60">
                I&apos;m 18 or older
              </Button>
              <Button onClick={() => startSession(false)} variant="transparent" className="w-60">
                I&apos;m 17 or younger
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEmailForm = () => {
    return (
      <>
        <DialogTitle className="text-center leading-6">Enter your email</DialogTitle>

        <div className="flex flex-col items-center justify-center pt-3">
          {updatingEmail ? (
            <LoadingIndicator />
          ) : (
            <form onSubmit={handleSubmit(saveEmail)} className="flex flex-col items-center gap-y-2">
              <div className="pb-2">
                <Input
                  placeholder="Email"
                  autoComplete="email"
                  className="w-60 text-black"
                  {...register("email")}
                />
                {errors.email?.message && (
                  <ErrorMessage className="text-white">{errors.email.message}</ErrorMessage>
                )}
              </div>
              <Button type="submit" variant="white" className="w-60">
                Submit
              </Button>
              <Button onClick={skipEmailPrompt} variant="transparent" className="w-60">
                Skip
              </Button>
            </form>
          )}
        </div>
      </>
    );
  };

  return (
    <Dialog open={props.open}>
      <DialogContent
        className="flex w-[400px] flex-col items-center justify-center !rounded-3xl !border-none bg-[#914dec] text-white"
        hideClose={true}
      >
        <DialogHeader>
          {showEmailForm ? renderEmailForm() : renderGuestSessionPrompt()}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
