import StepHeader from "./step-header";
import { useGetPlayerCard } from "hooks/card/useGetPlayerCard";

type Props = {
  title: string;
  subtitle?: string;
  tooltipOnLoad?: string;
};

export default function PlayerStepHeader({ title, subtitle, tooltipOnLoad }: Props) {
  const { cardResult, isLoadingCard, isGeneratingCard } = useGetPlayerCard();
  const showLoadingCard = isLoadingCard || isGeneratingCard;
  const frontUrl = cardResult?.card_preview?.card_front_preview_uri;
  const backUrl = cardResult?.card_preview?.card_back_preview_uri;

  return (
    <StepHeader
      title={title}
      frontUrl={frontUrl}
      backUrl={backUrl}
      subtitle={subtitle}
      loading={showLoadingCard}
      tooltipOnLoad={tooltipOnLoad}
    />
  );
}
