import { cn } from "@/utils/index";
import { forwardRef } from "react";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & { label?: string };

const FormInput = forwardRef<HTMLInputElement, InputProps>(
  ({ label, className, ...props }: InputProps, ref) => {
    const withLabel = (input: JSX.Element) => {
      if (!label) return input;
      return (
        <div className="flex flex-col">
          <label htmlFor={props.id} className="inline-block pb-2">
            {label}
          </label>
          {input}
        </div>
      );
    };

    return withLabel(
      <input className={cn("rounded-full p-2 px-4", className)} ref={ref} {...props} />
    );
  }
);

FormInput.displayName = "FormInput";

export default FormInput;
