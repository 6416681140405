import {
  useV1ServiceV1CardJamWorkspaceCardFullCreatePost,
  useV1ServiceV1CardJamWorkspaceCardVariantCreatePost
} from "common/api/queries";
import { CardFullCreateView, CardVariantCreateView } from "common/api/requests";
import { useOrderFormStore } from "store/order-form/order-form";
import { getCardUpdateView } from "@/components/create/about/get-card-update-view";
import useAuth from "hooks/useAuth";

export type CreateCardImage = {
  imageUrl: string;
  fileName: string;
  mimeType: string;
};

export type CreateCardVariant = {
  cardSetId: string;
  cardSetTemplate: string;
  cardVariantBase: string;
  cardVariantAttributes: string[];
};

type CreateCardMutationOptions = {
  onError?: (error: Error) => void;
};

export const useCreateCardMutation = ({ onError }: CreateCardMutationOptions = {}) => {
  const { session } = useAuth();
  const workspaceId = session?.workspaceId;

  const {
    data: cardResult,
    mutateAsync,
    isPending
  } = useV1ServiceV1CardJamWorkspaceCardFullCreatePost({
    onError,
    networkMode: "always"
  });

  const { mutateAsync: createVariant, isPending: creatingVariant } =
    useV1ServiceV1CardJamWorkspaceCardVariantCreatePost({
      networkMode: "always"
    });

  const { cardStepData, setPhotoStep } = useOrderFormStore();

  const objectUrlToFile = async (objectUrl: string, filename: string) => {
    const response = await fetch(objectUrl);
    const blob = await response.blob();

    return new File([blob], filename, { type: blob.type });
  };

  const createCard = async (image: CreateCardImage, variant: CreateCardVariant) => {
    const formData = new FormData();

    const file = await objectUrlToFile(image.imageUrl, image.fileName);
    formData.append("photo_file", file, image.fileName);

    const hasAthleteInfo =
      cardStepData?.aboutAthleteFrontStep || cardStepData?.aboutAthleteBackStep;
    const athleteInfo = hasAthleteInfo
      ? getCardUpdateView({
          ...cardStepData?.aboutAthleteFrontStep,
          ...cardStepData?.aboutAthleteBackStep
        })
      : undefined;
    const jsonData: CardFullCreateView = {
      card_set_id: variant.cardSetId,
      card_set_template: variant.cardSetTemplate as CardFullCreateView["card_set_template"],
      card_variant_base: variant.cardVariantBase as CardFullCreateView["card_variant_base"],
      card_variant_attributes:
        variant.cardVariantAttributes as CardFullCreateView["card_variant_attributes"],
      ...(hasAthleteInfo ? athleteInfo : {})
    };

    formData.append("data", JSON.stringify(jsonData));

    const res = await mutateAsync({
      data: formData as any, //TODO: Fix the Swagger types
      workspaceId
    });
    const cacheKey = getCacheKey(
      variant.cardSetId,
      variant.cardSetTemplate,
      variant.cardSetTemplate,
      variant.cardVariantAttributes
    );
    const cache = {};
    cache[cacheKey] = res.id;
    setCache(cache);

    return res.id;
  };

  const getCacheKey = (
    newSetId: string,
    cardSetTemplate: string,
    cardVariantBase: string,
    attributes: string[]
  ) => {
    return `${newSetId}${cardSetTemplate}${cardVariantBase}${attributes}`;
  };

  const getCache = () => {
    return cardStepData.photoStep?.variantCardIdCache ?? {};
  };

  const setCache = (cache: Record<string, string>) => {
    setPhotoStep({ variantCardIdCache: cache });
  };

  const getCachedVariant = (
    setId: string,
    template: string,
    variant: string,
    attributes: string[]
  ): string | undefined => {
    const cache = getCache();
    const cacheKey = getCacheKey(setId, template, variant, attributes);
    return cache[cacheKey];
  };

  const createCardVariant = async (
    previousCardId: string,
    newSetId: string,
    newTemplate: string,
    newVariant: string,
    newAttributes: string[]
  ) => {
    const res = await createVariant({
      data: {
        card_set_id: newSetId,
        card_set_template: newTemplate as CardVariantCreateView["card_set_template"],
        card_variant_base: newVariant as CardVariantCreateView["card_variant_base"],
        card_variant_attributes: newAttributes as CardVariantCreateView["card_variant_attributes"]
      },
      cardId: previousCardId,
      workspaceId
    });
    const newCardId = res.id;

    const cache = getCache();
    const cacheKey = getCacheKey(newSetId, newTemplate, newVariant, newAttributes);
    cache[cacheKey] = newCardId;
    setCache(cache);

    return newCardId;
  };

  return {
    createCard,
    createCardVariant,
    getCachedVariant,
    isPending,
    creatingVariant,
    cardResult
  };
};
