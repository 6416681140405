import { cn } from "@/utils/index";

export function ErrorMessage({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={cn("pt-1 text-[0.93rem] text-red-600", className)} {...props}>
      {children}
    </p>
  );
}
