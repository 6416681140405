import confetti from "canvas-confetti";
import { useState } from "react";

type Animator = {
  start: (onEnd: () => void) => void;
  reset: () => void;
  packOpeningFrame: number;
};

export const usePackOpeningAnimation = (): Animator => {
  const [packOpeningFrame, setPackOpeningFrame] = useState(0);

  const start = (onEnd: () => void) => {
    setPackOpeningFrame(1);
    setTimeout(() => {
      setPackOpeningFrame(2);
    }, 300);
    setTimeout(() => {
      confetti({
        particleCount: 200,
        startVelocity: 30,
        spread: 360,
        origin: {
          y: 0.5 + (Math.random() - 0.5) * 0.1,
          x: 0.5 + (Math.random() - 0.5) * 0.1
        },
        colors: ["#cfda28", "#914dec"]
      });
      setPackOpeningFrame(3);
    }, 900);
    setTimeout(() => {
      onEnd();
    }, 3000);
  };

  const reset = () => {
    setPackOpeningFrame(0);
  };

  return { start, packOpeningFrame, reset };
};
