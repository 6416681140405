import { LayeredCardPreviews } from "../card/LayeredPreview";
import Title from "./title";
import useScrollPosition from "hooks/use-scroll-position";
import { cn } from "@/utils/index";
import { useEffect, useState } from "react";
import usePreviousValue from "hooks/use-previous-value";

type Props = {
  title: string;
  subtitle?: string;
  frontUrl?: string;
  backUrl?: string;
  loading?: boolean;
  tooltipOnLoad?: string;
};

export default function StepHeader({
  title,
  subtitle,
  frontUrl,
  backUrl,
  tooltipOnLoad,
  loading = false
}: Props) {
  const scrollPosition = useScrollPosition();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const previousLoading = usePreviousValue(loading);

  useEffect(() => {
    if (!tooltipOnLoad) return;
    if (previousLoading && !loading) {
      setTooltipOpen(true);
      const timeout = setTimeout(() => setTooltipOpen(false), 4000);
      return () => clearTimeout(timeout);
    }
  }, [tooltipOnLoad, loading]);

  return (
    <div
      className={cn(
        "bg-legend-green sticky top-0 z-20 flex rounded-b-xl p-4 pb-0 pb-4 md:top-auto md:block md:grid-cols-1 md:p-0 md:shadow-none",
        {
          "shadow-lg": scrollPosition > 99
        }
      )}
    >
      <div className="xs:pr-3 xs:pt-2 flex flex-grow flex-col pr-2 pt-1 md:pr-0 md:pt-0">
        <Title
          className={cn({
            "text-3xl md:pt-0": !subtitle
          })}
        >
          {title}
        </Title>
        <div className="xs:mt-2 mt-1 md:mt-3">{subtitle}</div>
      </div>
      {frontUrl && backUrl && (
        <div className="flex flex-col items-center justify-end md:hidden">
          <div
            className={cn({
              "animate-jiggle": tooltipOpen
            })}
          >
            <LayeredCardPreviews frontUrl={frontUrl} backUrl={backUrl} loading={loading} />
          </div>
          <div
            className={cn("relative flex h-0 w-full select-none justify-center", {
              "animate-fade-in-down": tooltipOpen,
              hidden: !tooltipOpen
            })}
          >
            <div className="bg-legend-purple absolute mt-1 h-3 w-3 rotate-45"></div>
            <div
              className={
                "bg-legend-purple absolute z-30 mt-2 flex h-8 items-center justify-center rounded-full px-3 text-sm text-xs text-white shadow-lg"
              }
            >
              {tooltipOnLoad}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
