import { cn } from "@/utils/index";
import { HTMLAttributes } from "react";

export default function ButtonFooter({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <>
      <div
        className={cn(
          "bg-legend-green fixed bottom-0 left-0 z-30 flex w-full gap-x-3 rounded-lg p-4 [box-shadow:rgba(0,0,0,0.15)_0px_-2px_12px] md:relative md:p-0 md:shadow-none md:mt-[2px]",
          className
        )}
        {...rest}
      >
        {children}
      </div>
      <div className="pb-24 md:pb-0"></div>
    </>
  );
}
