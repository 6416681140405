import { FlippablePreview } from "legend-ui/components/card/flippable-preview";
import { useGetPlayerCard } from "../../hooks/card/useGetPlayerCard";
import { ReactNode } from "react";
import { ButtonProps } from "legend-ui";

type Props = {
  loading?: boolean;
  defaultFlipped?: boolean;
  hideControls?: boolean;
  loadingMessage?: string;
  extraControl?: ReactNode;
  buttonVariant?: ButtonProps["variant"];
};

export const PlayerFlippableCard = ({
  loadingMessage,
  extraControl,
  buttonVariant,
  loading = false,
  hideControls = false,
  defaultFlipped = false
}: Props) => {
  const { cardResult, isLoadingCard, isGeneratingCard } = useGetPlayerCard();
  const showLoadingCard = isLoadingCard || isGeneratingCard || loading;

  return cardResult?.card_preview?.card_front_preview_uri &&
    cardResult?.card_preview?.card_back_preview_uri ? (
    <FlippablePreview
      hideControls={hideControls}
      defaultFlipped={defaultFlipped}
      loading={showLoadingCard}
      loadingMessage={loadingMessage}
      extraControl={extraControl}
      front={{
        alt: "Card Preview front",
        src: cardResult?.card_preview?.card_front_preview_uri,
        height: 487,
        width: 340
      }}
      back={{
        alt: "Card Preview back",
        src: cardResult?.card_preview?.card_back_preview_uri,
        height: 487,
        width: 340
      }}
      buttonVariant={buttonVariant}
    />
  ) : null;
};
