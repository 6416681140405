import useAuth from "hooks/useAuth";
import { useV1ServiceV1CardJamWorkspaceCardRead } from "common/api/queries";
import { useOrderFormStore } from "store/order-form/order-form";
import { useEffect } from "react";

const GENERATING_STATUSES = ["generating", "submitted", "variant_generating", "variant_submitted"];

export const useGetPlayerCard = () => {
  const { session } = useAuth();
  const workspaceId = session?.workspaceId;
  const { cardStepData, setBlurbStep } = useOrderFormStore();
  const cardId = cardStepData.photoStep?.cardId;
  const blurbStepOverriden = cardStepData.blurbStep?.overriden;
  const currentBlurb = cardStepData.blurbStep?.blurb;
  const currentShortBlurb = cardStepData.blurbStep?.shortBlurb;
  const currentMediumBlurb = cardStepData.blurbStep?.mediumBlurb;

  const {
    data: cardResult,
    isLoading: isLoadingCard,
    isFetching: isFetchingCard,
    refetch
  } = useV1ServiceV1CardJamWorkspaceCardRead(
    {
      workspaceId,
      cardId
    },
    undefined,
    {
      enabled: !!workspaceId && !!cardId,
      refetchInterval: (query) => {
        return GENERATING_STATUSES.includes(query.state.data?.card_processing_status || "")
          ? 4000
          : undefined;
      }
    }
  );
  const apiBlurb = cardResult?.athlete_info?.blurb;
  const apiShortBlurb = cardResult?.athlete_info?.short_blurb;
  const apiMediumBlurb = cardResult?.athlete_info?.medium_blurb;

  const isGeneratingCard =
    cardResult != null && GENERATING_STATUSES.includes(cardResult.card_processing_status);

  useEffect(() => {
    if (apiBlurb && !blurbStepOverriden && apiBlurb !== currentBlurb) {
      setBlurbStep({
        blurb: apiBlurb,
        overriden: false
      });
    }
  }, [apiBlurb, currentBlurb, blurbStepOverriden]);

  useEffect(() => {
    if (currentShortBlurb !== apiShortBlurb || currentMediumBlurb !== apiMediumBlurb) {
      setBlurbStep({
        shortBlurb: apiShortBlurb,
        mediumBlurb: apiMediumBlurb
      });
    }
  }, [currentShortBlurb, currentMediumBlurb]);

  return {
    cardResult,
    isLoadingCard,
    isFetchingCard,
    isGeneratingCard,
    refetch
  };
};
