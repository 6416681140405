import Button from "@/components/buttons/button";
import { DialogHeader, Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ErrorMessage } from "../error-message";

const schema = yup.object({
  blurb: yup
    .string()
    .min(1, "Blurb is required")
    .max(300, "Blurb must be less than 300 characters")
    .required("Blurb is required")
});

type BlurbFormValues = {
  blurb: string;
};

type Props = {
  defaultBlurb: string;
  onCancel: () => void;
  onChange: (blurb: string) => void;
  open: boolean;
};

export default function EditBlurbModal({ open, onChange, defaultBlurb }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BlurbFormValues>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (values: BlurbFormValues) => {
    onChange(values.blurb);
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="bg-legend-purple max-w-[400px] md:max-w-[400px]"
        innerClassName=""
        hideClose={true}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-xl text-white">Edit blurb</DialogTitle>
        </DialogHeader>
        <form className="flex flex-grow flex-col gap-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-grow flex-col justify-start pt-4">
            <div className="overflow-hidden rounded-lg">
              <textarea
                className="h-full min-h-[200px] w-full resize-none px-3 py-2"
                defaultValue={defaultBlurb}
                {...register("blurb")}
              />
            </div>
            {errors.blurb && (
              <ErrorMessage className="text-white">{errors.blurb.message}</ErrorMessage>
            )}
          </div>
          <div className="flex flex-col justify-end">
            <div className="grid grid-cols-2 gap-x-4">
              <Button variant="white" type="submit">
                Cancel
              </Button>
              <Button variant="white" type="submit">
                Done
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
