import { cn } from "@/utils/index";
import { PropsWithChildren } from "react";

type Props = {
  outerClassName?: string;
  innerClassName?: string;
};

const Container = ({ children, outerClassName, innerClassName }: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(
        "bg-legend-green night-mode-element flex flex-grow flex-col items-center md:mx-4 md:mt-4 md:rounded-3xl",
        outerClassName
      )}
    >
      <div className={cn("w-full max-w-7xl px-4 py-4 md:px-12 md:py-10", innerClassName)}>
        {children}
      </div>
    </div>
  );
};

export default Container;
