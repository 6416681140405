import { queryClient } from "@/pages/_app";
import {
  useV1ServiceV1CardJamWorkspaceCardUpdatePost,
  useV1ServiceV1CardJamWorkspaceCardReadKey
} from "common/api/queries";
import { useOrderFormStore } from "store/order-form/order-form";
import { AboutAthleteValues, getCardUpdateView } from "./get-card-update-view";
import { CardStepData } from "store/order-form/card";
import { isEqual } from "lodash";
import useAuth from "hooks/useAuth";
import { showToast } from "legend-ui";

type UpdateCardOptions = {
  onUpdateError?: (error: unknown) => void;
  onRefreshError?: (error: unknown) => void;
};

export const useUpdateCard = ({ onRefreshError, onUpdateError }: UpdateCardOptions = {}) => {
  const {
    cardStepData: {
      photoStep,
      aboutAthleteFrontStep,
      aboutAthleteBackStep,
      blurbStep,
      attributesStep
    }
  } = useOrderFormStore();
  const { session } = useAuth();

  const { mutateAsync } = useV1ServiceV1CardJamWorkspaceCardUpdatePost({
    onSuccess: async () => {
      const cardKey = useV1ServiceV1CardJamWorkspaceCardReadKey;
      await queryClient.cancelQueries({
        queryKey: [cardKey, { cardId: photoStep.cardId, workspaceId: session.workspaceId }]
      });
      await queryClient.refetchQueries({
        queryKey: [cardKey, { cardId: photoStep.cardId, workspaceId: session.workspaceId }]
      });
    }
  });

  const updateCard = async (
    previousStepData: CardStepData,
    newData: Partial<AboutAthleteValues>,
    regenerateBlurb = false
  ): Promise<boolean> => {
    try {
      const workspaceId = session?.workspaceId;
      const cardId = photoStep?.cardId;
      const { aboutAthleteFrontStep, aboutAthleteBackStep, blurbStep } = previousStepData;
      const shouldRegenerateBlurb = !blurbStep.overriden && regenerateBlurb;
      const previousAboutAthleteValues: AboutAthleteValues = {
        ...aboutAthleteFrontStep,
        ...aboutAthleteBackStep,
        blurb: shouldRegenerateBlurb ? undefined : blurbStep?.blurb,
        shortBlurb: blurbStep?.shortBlurb,
        mediumBlurb: blurbStep?.mediumBlurb,
        gender: attributesStep?.gender,
        skinTone: attributesStep?.skinTone
      };
      const newAboutAthleteValues: AboutAthleteValues = {
        ...previousAboutAthleteValues,
        ...newData
      };
      if (isEqual(previousAboutAthleteValues, newAboutAthleteValues)) {
        return true;
      }
      const data = getCardUpdateView(newAboutAthleteValues);

      if (!workspaceId || !cardId) {
        return true;
      }

      await mutateAsync({
        data,
        workspaceId,
        cardId
      });

      return true;
    } catch (e) {
      if (onUpdateError) {
        onUpdateError(e);
      } else {
        showToast("We ran into an issue updating your card. Please try again.");
      }
      return false;
    }
  };

  const refreshCard = async () => {
    try {
      const workspaceId = session?.workspaceId;
      const cardId = photoStep?.cardId;

      if (!aboutAthleteFrontStep && !aboutAthleteBackStep) {
        return;
      }

      if (!workspaceId || !cardId) {
        return;
      }

      const data = getCardUpdateView({
        ...aboutAthleteFrontStep,
        ...aboutAthleteBackStep,
        blurb: blurbStep?.blurb,
        shortBlurb: blurbStep?.shortBlurb,
        mediumBlurb: blurbStep?.mediumBlurb,
        gender: attributesStep?.gender,
        skinTone: attributesStep?.skinTone
      });

      const result = await mutateAsync({
        data,
        workspaceId,
        cardId
      });
      return result;
    } catch (e) {
      if (onRefreshError) {
        onRefreshError(e);
        return;
      }
      showToast("We ran into an issue refreshing your card. Please try again.");
    }
  };

  return { updateCard, refreshCard };
};
