import Button from "../../buttons/button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOrderFormStore } from "store/order-form/order-form";
import { AboutAthleteFrontStep } from "store/order-form/card";
import * as yup from "yup";
import FormInput from "../form-input";
import { ErrorMessage } from "../error-message";
import InputRow from "./input-row";
import ButtonFooter from "../button-footer";
import { useGetPlayerCard } from "hooks/card/useGetPlayerCard";
import { showToast } from "legend-ui";
import isApiError from "@/utils/api/is-api-error";
import { useUpdateCard } from "./use-update-card";

type Props = {
  onBack: () => void;
  onSubmit: (values: AboutAthleteFrontStep) => void;
};

const schema = yup.object({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  number: yup
    .number()
    .typeError("Number is required")
    .integer("A whole number is required")
    .required("Number is required")
    .min(0, "Number must be positive")
    .max(99, "Number must be less than 100"),
  position: yup.string().trim().required("Position is required"),
  team: yup.string().trim().required("Team is required"),
  sport: yup.string().trim().required("Sport is required")
});

const fieldMappings = {
  first_name: "first name",
  last_name: "last name",
  player_number: "number",
  position: "position",
  team_name: "team name",
  primary_sport: "sport"
};

const apiFieldFormKeyMapping: Record<string, keyof AboutAthleteFrontStep> = {
  first_name: "firstName",
  last_name: "lastName",
  player_number: "number",
  position: "position",
  team_name: "team",
  primary_sport: "sport"
};

export default function AboutAthleteFrontForm({ onBack, onSubmit }: Props) {
  const { isGeneratingCard, isLoadingCard } = useGetPlayerCard();
  const { cardStepData } = useOrderFormStore();
  const defaultValues = cardStepData?.aboutAthleteFrontStep;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<AboutAthleteFrontStep>({ resolver: yupResolver(schema) });

  const onUpdateError = (error: unknown) => {
    if (isApiError(error)) {
      const athleteInfoMessages = error.body?.["athlete_info"];
      const fields = [
        "first_name",
        "last_name",
        "player_number",
        "position",
        "team_name",
        "primary_sport"
      ];
      for (const field of fields) {
        const message = athleteInfoMessages?.[field];
        const fieldName = fieldMappings[field];
        const formKey = apiFieldFormKeyMapping[field];
        if (message && fieldName && formKey) {
          showToast(`We ran into an issue with your ${fieldName}: ${message}`, { duration: 8000 });
          setError(formKey, { message });
          return;
        }
      }
    }
    showToast("We ran into an unexpected issue updating your card. Please try again.");
  };

  const { updateCard } = useUpdateCard({
    onUpdateError
  });

  const handleApiSubmit = async (values: AboutAthleteFrontStep) => {
    const success = await updateCard(cardStepData, values);
    if (success) {
      onSubmit(values);
    }
  };

  return (
    <form className="px-4 pt-2 md:px-0 md:pt-6" onSubmit={handleSubmit(handleApiSubmit)}>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <InputRow>
          <div className="flex flex-grow flex-col">
            <FormInput
              label="First name"
              placeholder="E.g. John"
              autoComplete="given-name"
              defaultValue={defaultValues?.firstName}
              {...register("firstName")}
            />
            {errors.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}
          </div>
          <div className="flex flex-col">
            <FormInput
              label="Last name"
              placeholder="E.g. Smith"
              autoComplete="family-name"
              defaultValue={defaultValues?.lastName}
              {...register("lastName")}
            />
            {errors.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
          </div>
        </InputRow>
        <InputRow>
          <div className="flex flex-col">
            <FormInput
              label="Number"
              placeholder="E.g. 88"
              defaultValue={defaultValues?.number}
              {...register("number")}
            />
            {errors.number && <ErrorMessage>{errors.number.message}</ErrorMessage>}
          </div>
          <div className="flex flex-col">
            <FormInput
              label="Position"
              placeholder="E.g. Quarterback"
              defaultValue={defaultValues?.position}
              {...register("position")}
            />
            {errors.position && <ErrorMessage>{errors.position.message}</ErrorMessage>}
          </div>
        </InputRow>
        <div className="flex flex-col">
          <FormInput
            label="Team"
            placeholder="E.g Cleveland Tigers"
            defaultValue={defaultValues?.team}
            {...register("team")}
          />
          {errors.team && <ErrorMessage>{errors.team.message}</ErrorMessage>}
        </div>
        <div className="flex flex-col">
          <FormInput
            label="Sport in picture"
            placeholder="E.g football, baseball, basketball, etc"
            defaultValue={defaultValues?.sport}
            {...register("sport")}
          />
          {errors.sport && <ErrorMessage>{errors.sport.message}</ErrorMessage>}
        </div>
      </div>
      <ButtonFooter className="md:pt-8">
        <Button type="button" className="flex-grow md:grow-0" variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button
          type="submit"
          className="flex-grow md:grow-0"
          loading={isGeneratingCard || isLoadingCard}
          disabled={isGeneratingCard || isLoadingCard}
        >
          Next
        </Button>
      </ButtonFooter>
    </form>
  );
}
