/* eslint-disable @next/next/no-img-element */
import { cn } from "@/utils/index";
import { SearchIcon } from "lucide-react";
import { useState } from "react";
import { LayeredPreviewModal } from "legend-ui/components/card/layered-preview-modal";
import { LoadingIndicator } from "legend-ui/components/loading-indicator";

type Props = {
  frontUrl: string;
  backUrl: string;
  loading?: boolean;
};

export const LayeredCardPreviews = ({ frontUrl, backUrl, loading }: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  if (!frontUrl || !backUrl) return null;
  return (
    <>
      <div className="relative h-full w-full cursor-pointer" onClick={() => setPreviewOpen(true)}>
        <div className="xs:h-[156px] xs:w-[128px] relative flex h-[150px] w-[122px]">
          <div className="xs:w-[100px] absolute right-0 top-0 z-10 w-[95px]">
            <img src={backUrl} className="h-full w-full" alt={"Back preview"} />
            <div
              className={cn(
                "absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/25",
                {
                  hidden: !loading
                }
              )}
            />
          </div>
          <div className="xs:w-[100px] absolute bottom-0 left-0 z-20 w-[95px]">
            <img src={frontUrl} alt={"Front preview"} className="h-full w-full" />
            <div
              className={cn(
                "absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/25",
                {
                  hidden: !loading
                }
              )}
            >
              <LoadingIndicator size="small" />
            </div>
          </div>
        </div>
        <div
          className={cn(
            "absolute bottom-[3px] right-[3px] z-30 flex h-8 w-8 items-center justify-center rounded-lg bg-white/85",
            {
              hidden: loading
            }
          )}
        >
          <SearchIcon strokeWidth={3} width={20} height={20} />
        </div>
      </div>
      <LayeredPreviewModal
        open={previewOpen}
        frontUrl={frontUrl}
        backUrl={backUrl}
        onBack={() => setPreviewOpen(false)}
      />
    </>
  );
};
