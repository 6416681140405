/* eslint-disable @next/next/no-img-element */
import { cn } from "common/utils";
import packStyles from "./pack.module.css";

type Props = {
  className?: string;
  packOpeningFrame: number;
  onClick?: () => void;
};

export default function PackOpening({ packOpeningFrame, onClick, className }: Props) {
  return (
    <div
      className={cn(
        "group relative flex h-full w-full flex-grow items-center justify-center",
        className
      )}
    >
      <div className="relative [perspective:1000px]">
        <div
          className={cn("relative z-20 [transform-style:preserve-3d] [transition:1s_linear]", {
            "[transform:translateZ(-75px)]": packOpeningFrame >= 1,
            "animate-burst-shake": packOpeningFrame < 1,
            "cursor-pointer": !!onClick
          })}
        >
          <img
            src="/assets/sl-foil-pack-tear-horizontal.png"
            alt="SL Foil Pack"
            className={cn("relative z-30 h-[300px] w-[300px]", {
              hidden: packOpeningFrame < 3
            })}
          />
          <div
            className={cn(packStyles.pack, {
              "[transform:rotateY(360deg)]": packOpeningFrame >= 1,
              hidden: packOpeningFrame > 2
            })}
            onClick={onClick}
          >
            <div className={packStyles.front}></div>
            <div className={packStyles.back}></div>
          </div>
        </div>
        <div
          className={cn(
            "pointer-events-none absolute left-[50px] top-[-85px] z-0 h-[250px] w-[250px] transform opacity-0 transition-opacity duration-500",
            {
              "opacity-1": packOpeningFrame >= 3
            }
          )}
          style={{
            background: "radial-gradient(circle, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 70%)"
          }}
        ></div>
      </div>
    </div>
  );
}
