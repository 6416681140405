import "cropperjs/dist/cropper.css";
import Button from "@/components/buttons/button";
import { Cropper, ReactCropperElement } from "react-cropper";
import { DialogHeader, Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { useRef } from "react";
import { RotateCcwSquare } from "lucide-react";
import { showToast } from "legend-ui";

type Props = {
  imageUrl: string;
  onClose: () => void;
  onComplete: (url: string) => void;
  open: boolean;
};

export default function EditImageModal({ open, imageUrl, onComplete, onClose }: Props) {
  const cropperRef = useRef<ReactCropperElement>(null);

  const onDone = () => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) {
      onClose();
      return;
    }
    const canvas = cropper.getCroppedCanvas({
      maxWidth: 3072,
      maxHeight: 3072
    });
    try {
      canvas.toBlob((blob) => {
        if (!blob) {
          showToast("Woops, we ran into an issue processing that photo");
          onClose();
          return;
        }
        const url = URL.createObjectURL(blob);
        onComplete(url);
      }, "image/jpeg");
    } catch (error) {
      showToast("Woops, we ran into an issue processing your photo.");
      onClose();
    }
  };

  const onRotate = () => {
    cropperRef.current?.cropper.rotate(-90);
  };

  return (
    <Dialog open={open}>
      <DialogContent className="bg-legend-green max-h-[95vh] text-black" hideClose={true}>
        <DialogHeader>
          <DialogTitle className="text-center text-xl text-black sm:text-3xl">
            Crop & rotate photo
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-y-6 pt-4 md:gap-y-6">
          <div className="max-h-[70vh] w-full">
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              className="h-full w-full"
              guides={false}
              background={false}
              autoCropArea={1}
              viewMode={2}
            />
          </div>
          <div className="grid grid-cols-3 gap-x-2 md:gap-x-4">
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button onClick={onRotate} icon={<RotateCcwSquare size={22} />} variant="outline">
              Rotate
            </Button>
            <Button onClick={onDone}>Done</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
