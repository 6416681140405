import { useEffect, useRef, useState } from "react";
import Button from "../../buttons/button";
import Container from "../../containers/container";
import { CreateCardVariant, useCreateCardMutation } from "../../../hooks/card/useCreateCard";
import { useGetPlayerCard } from "../../../hooks/card/useGetPlayerCard";
import * as Progress from "@radix-ui/react-progress";
import { PlayerFlippableCard } from "@/components/card/PlayerFlippableCard";
import { useOrderFormStore } from "store/order-form/order-form";
import useScrollToTop from "hooks/use-scroll-to-top";
import ButtonFooter from "../button-footer";
import StepHeader from "../step-header";
import { cn } from "@/utils/index";
import { useUpdateCard } from "../about/use-update-card";
import { CardData } from "store/order-form/card";
import EditImage from "./edit-image";
import { v4 } from "uuid";
import useProgress from "./use-progress";
import {
  WorkspaceCardSetResponse,
  WorkspaceCardSetTemplateVariantBaseResponse
} from "common/api/requests";
import { LoadingIndicator, showToast } from "legend-ui";
import {
  useV1ServiceV1CardJamOpenUnopenedCardPackPost,
  useV1ServiceV1CardJamWorkspacePost
} from "common/api/queries";
import useAuth from "hooks/useAuth";
import { isDefaultDesignForSet } from "@/utils/reward";
import PackOpening from "@/components/rewards/pack-opening";
import { usePackOpeningAnimation } from "@/components/rewards/use-pack-opening-animation";
import { sendMetaEvent } from "@/utils/send-meta-event";
import { sendGAEvent } from "@next/third-parties/google";

type Props = {
  onNext: () => void;
  sets: WorkspaceCardSetResponse[];
  setsLoading: boolean;
  refetchSets: () => void;
};

const UploadOverview = ({ onNext, sets, setsLoading, refetchSets }: Props) => {
  useScrollToTop();
  const { setPhotoStep, setCardStep, cardStepData } = useOrderFormStore();
  const { refreshCard } = useUpdateCard();
  const auth = useAuth();
  const [imageBeingCropped, setImageBeingCropped] = useState<string>();
  const [needsPackOpening, setNeedsPackOpening] = useState(false);
  const [runningAnimation, setRunningAnimation] = useState(false);
  const waitForCardIdComplete = useRef<string>();
  const markedForRefresh = useRef(false);
  const photoStep = cardStepData.photoStep;
  const cardData = photoStep?.cardData;
  const cardImage = photoStep?.cardImage;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { createCard } = useCreateCardMutation();
  const { cardResult, isLoadingCard, isGeneratingCard } = useGetPlayerCard();
  const { mutateAsync: claimMarketingCampaign } = useV1ServiceV1CardJamWorkspacePost();
  const { progress, pollingForProgress, startPolling, stopPolling } = useProgress({
    cardResult
  });
  const {
    start: startAnimation,
    packOpeningFrame,
    reset: resetAnimation
  } = usePackOpeningAnimation();
  const { mutateAsync: claimUnopenedPack } = useV1ServiceV1CardJamOpenUnopenedCardPackPost();
  const cardGenerationFailed = cardResult?.card_processing_status === "failed";
  const showPreview = !needsPackOpening && cardResult?.card_preview;
  const showPackOpening = needsPackOpening && !cardGenerationFailed;
  const doingInitialCardFetch =
    !cardResult && !isGeneratingCard && !pollingForProgress && isLoadingCard;
  const cardResultIsRelevant =
    waitForCardIdComplete.current && waitForCardIdComplete.current === cardResult?.id;
  const [packReadyForOpening, setPackReadyForOpening] = useState(false);

  const handleNext = () => {
    if (markedForRefresh.current) {
      markedForRefresh.current = false;
      refreshCard();
    }
    onNext();
  };

  const triggerFileInput = () => {
    fileInputRef?.current?.click();
  };

  const handleFileUploadClick = () => {
    triggerFileInput();
  };

  const isSupportedImage = (filename: string) => {
    return ["heic", "jpg", "jpeg", "png"].some((extension) =>
      filename.toLowerCase().endsWith(extension)
    );
  };

  const isSupportedForCropping = (filename: string) => {
    return ["jpg", "jpeg", "png"].some((extension) => filename.toLowerCase().endsWith(extension));
  };

  const getDefaultVariant = (): CreateCardVariant | undefined => {
    const isDefaultBase = (base: WorkspaceCardSetTemplateVariantBaseResponse) =>
      base.default && base.active && !base.locked;
    const firstSetWithDefault = sets.find((set) =>
      set.card_set_template_variant_bases.some(isDefaultBase)
    );
    const firstDefaultBase =
      firstSetWithDefault?.card_set_template_variant_bases.find(isDefaultBase);
    if (!firstSetWithDefault || !firstDefaultBase) return;
    return {
      cardSetId: firstSetWithDefault.id,
      cardSetTemplate: firstDefaultBase.card_set_template,
      cardVariantBase: firstDefaultBase.card_variant_base,
      cardVariantAttributes: []
    };
  };

  const openBasePack = async (): Promise<CreateCardVariant | undefined> => {
    const campaignResults = await claimMarketingCampaign({
      data: {
        campaign_name: "Base Campaign"
      },
      workspaceId: auth?.session?.workspaceId
    });
    const unopenedPackId = campaignResults?.[0]?.unopened_card_pack_id;
    if (!unopenedPackId) return;
    const pack = await claimUnopenedPack({
      data: {
        count: 1
      },
      workspaceId: auth?.session?.workspaceId,
      unopenedCardPackId: unopenedPackId
    });
    if (pack.designs_won_list.length === 0) return;
    const design =
      pack.designs_won_list.find(
        (design) =>
          !isDefaultDesignForSet(
            sets,
            design.card_set_id,
            design.card_set_template,
            design.card_variant_base
          )
      ) ?? pack.designs_won_list[0];
    const attribute = pack.attributes_won_list?.[0];
    return {
      cardSetId: design.card_set_id,
      cardSetTemplate: design.card_set_template,
      cardVariantBase: design.card_variant_base,
      cardVariantAttributes: attribute ? [attribute.card_variant_attribute] : []
    };
  };

  const startCardCreation = async (cardData: CardData) => {
    try {
      startPolling();
      setNeedsPackOpening(true);
      const variant = (await openBasePack()) ?? getDefaultVariant();
      const cardId = await createCard(cardData, variant);
      waitForCardIdComplete.current = cardId;
      setPhotoStep({
        cardId,
        cardImage: cardData.imageUrl,
        cardData,
        setId: variant.cardSetId,
        template: variant.cardSetTemplate,
        variant: variant.cardVariantBase
      });
      sendMetaEvent("track", "CustomizeProduct");
      sendMetaEvent("trackCustom", "UploadPhoto");
      sendGAEvent("event", "view_item", {
        items: [
          {
            item_id: variant.cardSetId,
            item_name: variant.cardSetTemplate,
            item_variant: variant.cardVariantBase
          }
        ]
      });
      sendGAEvent("event", "photo_uploaded");
    } catch (error) {
      stopPolling();
      document.body.classList.remove("night-mode");
      setNeedsPackOpening(false);
      showToast("We ran into an unexpected error. Please try again.");
    }
  };

  const onImageCrop = (url: string) => {
    const data: CardData = {
      imageUrl: url,
      fileName: `${v4()}.jpg`,
      mimeType: "image/jpeg"
    };
    startCardCreation(data);
    setImageBeingCropped(undefined);
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const onImageChange = async (e) => {
    e.preventDefault();

    const files = e.target?.files || e.dataTransfer?.files;
    const fileName = files?.[0]?.name;

    if (!files?.length) {
      return;
    }
    if (files.length !== 1 || !isSupportedImage(fileName)) {
      showToast("An unsupported photo type was selected. Supported types are: jpeg, png or heic.");
      return;
    }

    const file = files[0];
    const objectUrl = URL.createObjectURL(file);

    if (!isSupportedForCropping(fileName)) {
      showToast(
        "Skipped cropping step due to an unsupported photo type. Supported types for cropping are: jpeg, png.",
        { duration: 6000 }
      );

      const lowerCaseExtensionName = fileName
        .replace(/\.[^/.]+$/, (match) => match.toLowerCase())
        .replace("jpeg", "jpg");
      const imageData: CardData = {
        imageUrl: objectUrl,
        fileName: lowerCaseExtensionName,
        mimeType: file.type
      };

      startCardCreation(imageData);

      return;
    }

    setImageBeingCropped(objectUrl);
  };

  const ChangePhotoButton = () => (
    <Button className="flex-grow md:grow-0" onClick={handleFileUploadClick}>
      {cardImage ? "Change photo" : "Choose photo"}
    </Button>
  );

  const handleCancelGeneration = () => {
    document.body.classList.remove("night-mode");
    setNeedsPackOpening(false);
    stopPolling();
    setCardStep({
      photoStep: undefined
    });
  };

  const onEnd = () => {
    setTimeout(() => {
      document.body.classList.remove("night-mode");
      resetAnimation();
      setPackReadyForOpening(false);
      setNeedsPackOpening(false);
      setRunningAnimation(false);
    }, 300);
  };

  const startPackOpening = () => {
    setRunningAnimation(true);
    startAnimation(onEnd);
  };

  const renderCards = () => (
    <>
      {showPreview && <PlayerFlippableCard hideControls />}
      {!doingInitialCardFetch && !showPreview && cardGenerationFailed && (
        <p className="px-4 py-16 text-center">
          Woops, we ran into an issue processing your photo. Please try again, or try a different
          photo.
        </p>
      )}
    </>
  );

  const renderAthletePhoto = () => (
    <>
      <div className="mb-12 flex flex-grow flex-col">
        <div className="hidden md:block">
          <StepHeader title="Athlete Photo" subtitle="Get started by uploading a photo" />
        </div>
        <div className="block md:hidden">
          <StepHeader
            title={showPreview ? "Your card" : "Athlete Photo"}
            subtitle={showPreview ? undefined : "Get started by uploading a photo"}
          />
        </div>
        <div className="flex flex-col justify-center px-4 pt-6 md:px-0">
          <div
            className={cn("flex flex-col pb-4 md:pb-7", {
              "hidden md:flex": showPreview || doingInitialCardFetch
            })}
          >
            <div className="flex">
              <span className="text-3xl font-bold">*</span>
              <span className="pl-2 text-lg">Try any photo! Each design is free.</span>
            </div>
            <div className="flex">
              <span className="text-3xl font-bold">*</span>
              <span className="pl-2 text-lg">
                We'll automatically center the athlete and remove the background.
              </span>
            </div>
            <div className="flex">
              <span className="text-3xl font-bold">*</span>
              <span className="pl-2 text-lg">Use our crop tool to focus on the athlete.</span>
            </div>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept={"image/jpeg, image/heic, image/heif, image/jpg, image/png"}
            onChange={onImageChange}
          />
          <div className="flex justify-center md:hidden">{renderCards()}</div>
          <ButtonFooter>
            <ChangePhotoButton />
            {cardData && (
              <Button
                className="flex-grow md:grow-0"
                disabled={doingInitialCardFetch}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </ButtonFooter>
        </div>
      </div>

      <div className="hidden md:flex md:justify-end">
        <div>{renderCards()}</div>
      </div>
      <EditImage
        open={!!imageBeingCropped}
        imageUrl={imageBeingCropped}
        onComplete={onImageCrop}
        onClose={() => {
          setImageBeingCropped(undefined);
          if (fileInputRef?.current) {
            fileInputRef.current.value = "";
          }
        }}
      />
    </>
  );

  const renderPackOpening = () => (
    <div className="flex w-full flex-grow flex-col items-center justify-center">
      <PackOpening
        packOpeningFrame={packOpeningFrame}
        className="h-auto flex-grow-0"
        onClick={packReadyForOpening ? startPackOpening : undefined}
      />
      <div
        className={cn("relative z-0 flex flex-col items-center overflow-x-hidden pt-10", {
          invisible: runningAnimation
        })}
      >
        {packReadyForOpening ? (
          <>
            <Button onClick={startPackOpening} variant="white" className="w-[180px] text-black">
              Open
            </Button>
          </>
        ) : (
          <>
            <p className="pb-6 text-sm text-white">Creating your pack</p>
            <Progress.Root className="translate-z-0 relative z-0 mb-4 h-2 w-[200px] overflow-x-hidden rounded-full bg-white/30">
              <Progress.Indicator
                className="h-full w-full bg-white [transition:transform_660ms_cubic-bezier(0.65,0,0.35,1)]"
                style={{ transform: `translateX(-${100 - (progress.value ?? 0)}%)` }}
              />
            </Progress.Root>
            <Button variant="transparent" className="text-sm" onClick={handleCancelGeneration}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );

  const renderView = () => {
    return needsPackOpening ? renderPackOpening() : renderAthletePhoto();
  };

  useEffect(() => {
    if (showPackOpening && !document.body.classList.contains("night-mode")) {
      document.body.classList.add("night-mode");
    }
  }, [showPackOpening]);

  useEffect(() => {
    const isRelevantCard = showPackOpening && waitForCardIdComplete.current === cardResult?.id;
    if (!isRelevantCard) return;
    if (cardResult?.card_processing_status === "completed") {
      setTimeout(() => {
        setPackReadyForOpening(true);
      }, 660);
      waitForCardIdComplete.current = undefined;
      return;
    }
    if (cardResult?.card_processing_status === "failed") {
      handleCancelGeneration();
      waitForCardIdComplete.current = undefined;
      return;
    }
  }, [showPackOpening, cardResult]);

  useEffect(
    () => () => {
      document.body.classList.remove("night-mode");
    },
    []
  );

  if (setsLoading || doingInitialCardFetch) {
    return (
      <Container innerClassName="py-8 px-0 flex justify-center">
        <LoadingIndicator variant="black" />
      </Container>
    );
  }

  return (
    <Container
      innerClassName={cn("pt-0 px-0", {
        "flex-grow flex justify-center": showPackOpening
      })}
    >
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">{renderView()}</div>
    </Container>
  );
};

export default UploadOverview;
