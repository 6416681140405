import { useDebounceCallback } from "usehooks-ts";
import Button from "../../buttons/button";
import Container from "../../containers/container";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useNumberInput
} from "@chakra-ui/number-input";
import Subtotal from "../../text/subtotal";
import { PlayerFlippableCard } from "../../card/PlayerFlippableCard";
import {
  useV1ServiceV1OrderManagementWorkspaceCartCartItemUpdate,
  useV1ServiceV1OrderManagementWorkspaceCartGet
} from "common/api/queries";
import { QuantityPrice } from "common/api/requests";
import { useFetchCardProductPricing } from "hooks/queries/useFetchCardProductPricing";
import { useOrderFormStore } from "store/order-form/order-form";
import PlayerStepHeader from "../player-step-header";
import useAuth from "hooks/useAuth";
import { useRouter } from "next/router";
import ButtonFooter from "../button-footer";
import { MinusIcon, PlusIcon } from "lucide-react";
import { LoadingIndicator } from "legend-ui";
import { useState } from "react";

const SelectQuantity = () => {
  const router = useRouter();
  const { cardStepData, clear: clearOrderFormStore } = useOrderFormStore();
  const cardId = cardStepData?.confirmStep?.confirmedCardId;
  const { session } = useAuth();
  const {
    data: cartData,
    isLoading,
    isFetching,
    refetch: refetchCart
  } = useV1ServiceV1OrderManagementWorkspaceCartGet({
    workspaceId: session?.workspaceId || "",
    page: 1
  });
  const [resettingOrderForm, setResettingOrderForm] = useState(false);
  const { mutateAsync: updateItemInCart } =
    useV1ServiceV1OrderManagementWorkspaceCartCartItemUpdate({
      onSuccess: refetchCart
    });

  const currentCartItem = cartData?.cart_items?.find((c) => c.product_id === cardId);
  const serverSubTotal = currentCartItem?.total_price;

  const handleQuantityChange = (val: number) => {
    if (!val) return;
    if (!cartData) return;

    const currentItem = cartData.cart_items?.find((c) => c.product_id === cardId);
    if (!currentItem) return;

    updateItemInCart({
      cartItemId: currentItem.id,
      workspaceId: session?.workspaceId || "",
      data: {
        quantity: val
      }
    });
  };

  const createAnotherCard = () => {
    setResettingOrderForm(true);
    clearOrderFormStore();
    router.reload();
  };

  const handleNext = async () => {
    await router.push("/review");
    clearOrderFormStore();
  };

  const debouncedHandleQuantityChange = useDebounceCallback(handleQuantityChange, 250);

  const renderQuantitySelector = () => (
    <div className="px-4 md:px-0">
      <SelectQuantityContainer
        cardId={cardId}
        setQuantity={debouncedHandleQuantityChange}
        cartData={cartData}
      />
      {!isFetching && serverSubTotal && <Subtotal subTotal={serverSubTotal} />}
      {isFetching && (
        <div className="pt-4">
          <LoadingIndicator variant="black" size="small" />
        </div>
      )}
      <ButtonFooter className="md:pt-4">
        <Button
          className="flex-grow md:grow-0"
          variant="outline"
          onClick={createAnotherCard}
          loading={resettingOrderForm}
        >
          Create another card
        </Button>
        <Button className="flex-grow md:grow-0" onClick={handleNext}>
          Next
        </Button>
      </ButtonFooter>
    </div>
  );

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        <div className="flex flex-grow flex-col">
          <PlayerStepHeader title="Select quantity" />
          {isLoading ? (
            <div className="flex items-center justify-center pt-6 md:pt-12">
              <LoadingIndicator variant="black" />
            </div>
          ) : (
            renderQuantitySelector()
          )}
        </div>

        <div className="hidden justify-center md:flex md:justify-end">
          <PlayerFlippableCard />
        </div>
      </div>
    </Container>
  );
};

const SelectQuantityContainer = ({ setQuantity, cardId, cartData }) => {
  const { productPricingData } = useFetchCardProductPricing();
  const currentItem = cartData?.cart_items?.find((c) => c.product_id === cardId);
  const currentItemPrice = currentItem?.item_price;
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    defaultValue: currentItem?.quantity ?? 1,
    min: 1,
    max: 999,
    inputMode: "numeric",
    onChange: (value) => {
      if (Number(value) < 1000) {
        setQuantity(value);
      }
    }
  });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <div className="mt-2">
      <div className="flex flex-col items-center justify-center gap-4 rounded-lg bg-white/20 p-4 py-5 md:flex-row md:justify-start md:gap-16">
        <div className="relative flex items-center overflow-hidden md:hidden">
          <button
            className="absolute left-0 top-0 z-10 flex h-12 w-10 items-center justify-center rounded-lg text-[#FF00C3]"
            {...dec}
          >
            <MinusIcon size={18} strokeWidth={3} />
          </button>
          <input
            className="h-12 w-32 rounded-lg bg-white/50 text-center text-lg caret-[#FF00C3] outline-none"
            {...input}
          />
          <button
            className="absolute right-0 top-0 z-10 flex h-12 w-10 items-center justify-center rounded-lg text-[#FF00C3]"
            {...inc}
          >
            <PlusIcon size={18} strokeWidth={3} />
          </button>
        </div>
        <NumberInput
          defaultValue={currentItem?.quantity ?? 1}
          min={1}
          max={999}
          onChange={(value) => setQuantity(value)}
          className="hidden w-28 md:block"
        >
          <NumberInputField height={"48px"} className="rounded-md px-2 text-lg" bgColor="white" />
          <NumberInputStepper className="pr-1">
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <div className="flex w-full flex-col md:w-auto md:flex-grow">
          <p className="pb-2 pl-6 text-sm">Our pricing</p>
          {productPricingData?.quantity_price_array?.map((entry, i) => (
            <PriceOptionBuilder key={i} {...entry} active={currentItemPrice === entry.price} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PriceOptionBuilder = ({
  quantity_min,
  quantity_max,
  price,
  active
}: QuantityPrice & { active?: boolean }) => {
  if (quantity_min === 1 && quantity_max === 1) {
    return <PriceOption range={`1 card`} price={price} active={active} />;
  } else if (quantity_max === 999) {
    return <PriceOption range={`${quantity_min}+`} price={price} active={active} />;
  } else {
    return <PriceOption range={`${quantity_min}-${quantity_max}`} price={price} active={active} />;
  }
};

const PriceOption = ({
  range,
  price,
  active
}: {
  range: string;
  price: string;
  active?: boolean;
}) => {
  return (
    <div className="flex flex-row items-center space-x-2 font-mono">
      <div className="min-w-3">{active && <SelectedMaker />}</div>
      <div className="flex flex-grow flex-row items-center">
        <div className="mb-2 flex h-14 w-full items-center space-x-2 rounded-xl bg-white/20 px-4 md:h-16">
          <span className="min-w-12">{range}</span>
          <span>{`($${price} each)`}</span>
        </div>
      </div>
    </div>
  );
};

const SelectedMaker = () => {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9822 6.92385C12.0073 7.72459 12.0073 9.27541 10.9822 10.0761L3.48116 15.9354C2.16805 16.9611 0.249997 16.0254 0.249997 14.3592L0.249998 2.64078C0.249998 0.974559 2.16805 0.0389403 3.48116 1.06464L10.9822 6.92385Z"
        fill="#FF00C3"
      />
    </svg>
  );
};

export default SelectQuantity;
