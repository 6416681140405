import { cn } from "@/utils/index";
import { VariantProps, cva } from "class-variance-authority";
import { LoadingIndicator } from "legend-ui";
import { ReactNode } from "react";

const buttonVariants = cva(
  "hover:shadow-md relative border border-2 active:shadow-sm px-6 py-2 flex items-center justify-center font-semibold text-base rounded-full disabled:opacity-70 disabled:shadow-none select-none",
  {
    variants: {
      variant: {
        default:
          "bg-black border-black hover:bg-gray-800 active:bg-gray-900 text-white disabled:hover:bg-black hover:bg-black",
        outline: "border-black text-black bg-transparent",
        "outline-white": "border-white text-white bg-transparent",
        white: "border-white text-legend-purple bg-white ",
        transparent:
          "border-transparent text-white bg-transparent hover:shadow-none hover:underline decoration-2"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
    icon?: ReactNode;
  };

const Button = ({ loading, children, variant, icon, className, ...props }: ButtonProps) => {
  return (
    <button className={cn(buttonVariants({ variant, className }))} {...props}>
      <span
        className={cn(
          "xs:gap-x-2 flex items-center justify-center gap-x-1.5",
          loading && "opacity-0"
        )}
      >
        {icon}
        {children}
      </span>
      {loading && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <LoadingIndicator
            variant={["white", "outline"].includes(variant) ? "black" : "white"}
            size="small"
          />
        </div>
      )}
    </button>
  );
};

export default Button;
