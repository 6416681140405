import { ReactNode } from "react";

export const CardLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-[380px] w-[268px] max-w-sm flex-none border border-gray-200 bg-gray-100 px-3 py-4 shadow-md md:h-[430px] md:w-[303px] xl:h-[483px] xl:w-[340px] z-10 relative">
      <div className="h-full bg-[#FF00C3]">
        <div className="h-full w-full">{children}</div>
      </div>
    </div>
  );
};
