import Container from "../../containers/container";
import { PlayerFlippableCard } from "../../card/PlayerFlippableCard";
import { useOrderFormStore } from "store/order-form/order-form";
import { AboutAthleteFrontStep } from "store/order-form/card";
import AboutAthleteFrontForm from "./about-athlete-front-form";
import useScrollToTop from "hooks/use-scroll-to-top";
import PlayerStepHeader from "../player-step-header";

const AboutAthleteFront = ({ onBack, onNext }) => {
  useScrollToTop();
  const { setCardStep } = useOrderFormStore();

  const handleNext = (values: AboutAthleteFrontStep) => {
    setCardStep({
      aboutAthleteFrontStep: values
    });
    onNext();
  };

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        <div className="flex flex-grow flex-col">
          <PlayerStepHeader
            title={"Front: Athlete's info"}
            subtitle={"Fill out the info about your athlete."}
          />
          <AboutAthleteFrontForm onBack={onBack} onSubmit={handleNext} />
        </div>

        <div className="hidden justify-center md:flex md:justify-end">
          <PlayerFlippableCard />
        </div>
      </div>
    </Container>
  );
};

export default AboutAthleteFront;
